//import './vendor/infinite-scroll.pkgd';

( function($) {

const $scope = $('body')
  .attr('class')
  .replace('s-', '');


$(window).on('load resize', () => {
  /**
   * Header アクティブメニュー
   */
  /*
  $('.p-header__nav > a').each(function () {
    if ($scope === 'home') {
      $('.p-header__nav-item--home').addClass('is-active');
    } else if ($(this).attr('href').split('/')[1] === window.location.pathname.split('/')[1]) {
      $(this).addClass('is-active');
    } else {
      //
    }
  });
  */
});

// header スクロールで縮小
$(function() {


  // fadein 設定
  const fade_bottom = 130; // 画面下からどの位置でフェードさせるか(px)
  const fade_move = 100; // どのぐらい要素を動かすか(px)
  const fade_time = 900; // フェードの時間(ms)

  // 内部リンクがない時のみ実施
  if (!location.hash) {
    $(".fadein").css({
      opacity: 0,
      transform: "translateY(" + fade_move + "px)",
      transition: fade_time + "ms",
    });
  }

  // スクロール時の処理
  function check_scroll() {
		var scrollPos = $(this).scrollTop();

    // header スクロール判定
		if ( scrollPos > 86 ) {
			$('.l-header').addClass('is-scroll');
      $('.p-header').addClass('is-scroll');
		} else {
			$('.l-header').removeClass('is-scroll');
      $('.p-header').removeClass('is-scroll');
		}

    // fadein 処理
    const scroll_bottom = scrollPos + $(this).height();
    const fade_position = scroll_bottom - fade_bottom;
    $(".fadein").each(function () {

      const this_position = $(this).offset().top;
      if (fade_position > this_position) {
        $(this).css({
          opacity: 1,
          transform: "translateY(0)",
        });
      }
    });

    // 見出し　黄色背景スライド処理
    $(".c-typo__yellow").each(function () {
      const this_position = $(this).offset().top;
      if (this_position < (scrollPos + 500) && scrollPos < (this_position + 500)) {
        $(this).addClass('c-typo__yellow--long');
      } else {
        //$(this).removeClass('c-typo__yellow--long');
      }
    });

    // ズーム処理
    /*
    $(".zoom").each(function () {
      const this_position = $(this).offset().top;
      if (this_position < (scrollPos + 300) && scrollPos < (this_position + 300)) {
      //if (fade_position > this_position) {
        $(this).css({
          transform: "scale(1.2,1.2)",
          transition: "1s all"
        });
      } else {
        $(this).css({
          transform: "scale(1,1)",
          transition: "1s all"
        });
      }
    });
    */
  }

  check_scroll();

  // スクロール時
	$(window).on('load scroll', function() {
    check_scroll();
	});
});

// header modal sns
$('.p-header__top--sns').on('click', function () {
  $('.p-header__sns').addClass('active');
});
$('.p-header__sns--close').on('click', function () {
  $('.p-header__sns').removeClass('active');
});

// header modal language
$('.p-header__top--language').on('click', function () {
  $('.p-header__language').addClass('active');
});
$('.p-header__language--close').on('click', function () {
  $('.p-header__language').removeClass('active');
});

// header modal search
$('.p-header__top--search').on('click', function () {
  $('.p-header__search').addClass('active');
});
$('.p-header__search--close').on('click', function () {
  $('.p-header__search').removeClass('active');
});


// header モバイル用メニュー表示
$('.p-header__button').on('click', function () {
  if ($(this).hasClass('active')) {
    $('body').css('overflow', '');
    $('body').removeClass('is-slidedown');
    $('.modal').hide();
    $(this).removeClass('active');
  } else {
    $('body').css('overflow', 'hidden');
    $('body').addClass('is-slidedown');
    $('.modal').show();
    $(this).addClass('active');
  }
});
$('.p-header__mask').on('click', function() {
  $('body').css('overflow', '');
  $('body').removeClass('is-slidedown');
  $('.modal').hide();
  $('.p-header__button').removeClass('active');
})


/**
  * ページ上部に戻る
  */
 $('.p-footer__pagetop').hide();
 $(window).scroll(function(){
    var scrollPoint = $(this).scrollTop();
    (scrollPoint > 200) ? $('.p-footer__pagetop').fadeIn()
    :( scrollPoint < 200) ? $('.p-footer__pagetop').fadeOut():$('.p-footer__pagetop').show();

    // 下部にスクロールしたら定位置でストップする
    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();
    footHeight = $(".l-footer").innerHeight();

    if ( scrollHeight - scrollPosition  <= footHeight ) {
      $(".p-footer__pagetop").css({
        "position":"absolute",
        "bottom": "auto"
      });
    } else {

      margin_bottom = '50px';

      if (document.documentElement.clientWidth <= 768) {
        margin_bottom = '1rem';
      }

      $(".p-footer__pagetop").css({
          "position":"fixed",
          "bottom": margin_bottom
      });
    }
  });

  $('.p-footer__pagetop').on('click',function(){
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
  });


/**
 * home main image
 */
if ($('.s-home').length > 0) {

  // COLUMN swiper
  let swiper_column = new Swiper('.s-home__column--swiper', {
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    slidesPerView: 1,
    spaceBetween: 15,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      426: {
        slidesPerView: 2,
      },
      769: {
        slidesPerView: 3,
      },
    },
    speed: 1000,
    navigation: {  //ナビゲーションボタンを表示
      nextEl: '.s-home__column--next',
      prevEl: '.s-home__column--prev',
    },
  });


  //
  /*
  let swiper_event = new Swiper('.s-home__event--swiper', {
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    //loop: true,
    //effect: 'fade',
    slidesPerView: 1,
    spaceBetween: 15,
    breakpoints: {
      700: {
        slidesPerView: 3,
      },
    },
    speed: 1000,
    navigation: {  //ナビゲーションボタンを表示
      nextEl: '.s-home__event--next',
      prevEl: '.s-home__event--prev',
    },
  });
  */

  // swiper ボタン高さ調整
  $(window).on('load resize', () => {

    // column
    var column_img_height = $('.s-home__column--img').height();
    $('.s-home__column .swiper-button-next').css('top', column_img_height / 2);
    $('.s-home__column .swiper-button-prev').css('top', column_img_height / 2);

    // event
    /*
    var event_img_height = $('.s-home__event--img').height();
    $('.s-home__event .swiper-button-next').css('top', event_img_height / 2);
    $('.s-home__event .swiper-button-prev').css('top', event_img_height / 2);
    */

  });

  /**
   * ページ上部の画像をクロスフェード
   */
  /*
  let swiper = new Swiper('.swiper', {
    loop: false,
    effect: 'fade',
    slidesPerView: 1,
    allowTouchMove: false,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    speed: 8000,
  });

  setTimeout(() => {
    console.log('autoplay stop');
    swiper.autoplay.stop();
  }, 12000);
  */

  // スクロール時の非表示
  /*
  let scrollFrag;
  scrollFrag = false;
  $(window).on('scroll', function() {

    if (scrollFrag !== true) {
      scrollFrag = true;
      $('.c-splash').animate({ opacity: 0 }, { duration: 1000, easing: 'linear' });
      setTimeout(() => {
        $('.c-splash').css('display', 'none');
      }, 1000);
    }
  });
  */

  // GUIDE 画像
  function imageGuideResize() {
    if (window.matchMedia('(min-width: 769px)').matches) {
      // PC表示の時の処理
      $('.s-home__rental--guide').css('background-image', 'url('+ $('#guide_img').val() +')');
    } else {
      // スマホ表示の時の処理
      $('.s-home__rental--guide').css('background-image', 'url('+ $('#guide_sp_img').val() +')');
    }
  }
  imageGuideResize();
  $(window).on('load resize', () => {
    imageGuideResize();
  });


  // SNOW LIVE
  $(document).ready(function() {
    $('.s-home__snow-live--iframe a').fancybox({
      'type': 'iframe',
      'iframe': {
        'attr' : {
          'scrolling': 'no',
        },
      },
    });
  });

}


/**
 * アクティビティ一覧
 */
if ($('.s-activities').length > 0) {

  $('.s-activities__area').change(function() {
    reload_activity();
    /*
    area = $('#s-activities__area').val();
    window.location.href = area;
    */
  });

  /*
  $('.s-activities__season').change(function() {
    reload_activity();
  });
  */

  $('.s-activities__genre').change(function() {
    reload_activity();
    /*
    genre = $('#s-activities__genre').val();
    window.location.href = genre;
    */
  });


  /**
   * アクティビティページをリロードする
   *
   * パラメータa：エリアID
   * パラメータg：ジャンルID
   */
  function reload_activity() {

    url = new URL(location);
    url.searchParams.delete('a');
    url.searchParams.delete('g');

    // ページングリセット
    lang = '';
    if (document.documentElement.lang != 'ja') {
      lang = '/'+document.documentElement.lang;
    }
    url.pathname = lang + '/activity/';

    area = $('.s-activities__area option:selected').val();
    if (area != '') {
      url.searchParams.set('a',area);
      console.log(area);
    }

    genre = $('.s-activities__genre option:selected').val();
    if (genre != '') {
      url.searchParams.set('g',genre);
    }

    window.location.href = url;

  }

}

/**
 * トピックス詳細
 */
if ($('.s-information').length > 0) {

  var swiper_loop = true;
  if ($('.swiper-slide').length == 1) {
    swiper_loop = false;
  }

  let swiper_information = new Swiper('.s-information__pics--swiper', {
    slidesPerView: "auto",
    centeredSlides: true,
    loop: swiper_loop,
    spaceBetween: 20,
    speed: 1000,
    breakpoints: {
      768: {
        spaceBetween: 40,
      }
    },
    navigation: {  //ナビゲーションボタンを表示
      nextEl: '.s-information__pics--next',
      prevEl: '.s-information__pics--prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    on: {
      imagesReady: function () {
        this.slideTo((this.slides.length / 3), 1);
      },
      afterInit: function () {
        this.slideTo((this.slides.length / 3), 1);
      },
    },
  });

}

/**
 * アクティビティ詳細
 */
if ($('.s-activity').length > 0) {

  var swiper_loop = true;
  if ($('.swiper-slide').length == 1) {
    swiper_loop = false;
  }

  let swiper_activity = new Swiper('.s-activity__pics--swiper', {
    /*
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    */
    //slidesPerView: 1.25,
    slidesPerView: "auto",
    centeredSlides: true,
    loop: swiper_loop,
    spaceBetween: 20,
    /*
    breakpoints: {
      700: {
        slidesPerView: 3,
      },
      1000: {
        slidesPerView: 4,
      }
    },
    */
    speed: 1000,
    breakpoints: {
      768: {
        spaceBetween: 40,
      }
    },
    navigation: {  //ナビゲーションボタンを表示
      nextEl: '.s-activity__pics--next',
      prevEl: '.s-activity__pics--prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    on: {
      imagesReady: function () {
        this.slideTo((this.slides.length / 3), 1);
      },
      afterInit: function () {
        this.slideTo((this.slides.length / 3), 1);
      },
    },
  });
}

/**
 * エリア
 */
 if ($('.s-area').length > 0) {

  imageMapResize();

  // swiper setting
  $('.swiper').each(function(index, element){
    new Swiper('.s-area__pics--swiper'+(index + 1), {
      slidesPerView: "auto",
      spaceBetween: 20,
      centeredSlides: true,
      speed: 1000,
      loop: true,
      breakpoints: {
        768: {
          spaceBetween: 40,
        }
      },
      navigation: {  //ナビゲーションボタンを表示
        nextEl: '.s-area__pics--next'+(index + 1),
        prevEl: '.s-area__pics--prev'+(index + 1),
      },
      on: {
        imagesReady: function () {
          this.slideTo((this.slides.length / 3), 1);
        },
        afterInit: function () {
          this.slideTo((this.slides.length / 3), 1);
        },
      },
    });
  });

  // 置換の対象とするclass属性
  var $area_map = $('.s-area__map');
  var $area_coords = $('.s-area__map--coords');
  // 画像を切り替えるウィンドウサイズ
  var replaceWidth = 780;

  function imageSwitch() {
    // ウィンドウサイズを取得する
    var windowWidth = parseInt(window.innerWidth);

    $area_map.each(function() {
      var $this = $(this);

      // マップファイル名
      var img_file = $('#img_file').val();
      var img_file_sp = $('#img_file_sp').val();

      // キャッシュ対策用パラメータ
      var date = new Date();

      // pcに置換する。
      if(windowWidth >= replaceWidth) {
        //$this.attr('src', $this.attr('src').replace(sp, pc));
        $this.attr('src', '/images/'+img_file+'?'+date.getTime());
        $area_coords.attr('name', 'ImageMap');

      // spに置換する。
      } else {
        //$this.attr('src', $this.attr('src').replace(pc, sp));
        $this.attr('src', '/images/'+img_file_sp+'?'+date.getTime());
        $area_coords.attr('name', '');
      }
    });

    $area_map.css('display', 'block');

  }
  imageSwitch();

  // 動的なリサイズは操作後0.2秒経ってから処理を実行する。
  var resizeTimer;
  $(window).on('resize', function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      imageSwitch();
    }, 200);
  });

  $('.s-area__map--ac').on('click',function() {
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    position = target.offset().top;

    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}



/* マウスストーカー javascript 非表示 */
/*
var
//cursor = $(".cursor"),
follower = $(".c-cursor__follower"),
//cWidth = 8, //カーソルの大きさ
fWidth = 100, //フォロワーの大きさ
delay = 30, //数字を大きくするとフォロワーがより遅れて来る
mouseX = 0, //マウスのX座標
mouseY = 0, //マウスのY座標
posX = 0, //フォロワーのX座標
posY = 0; //フォロワーのX座標
moveTimer = 0;
delayTime = 1500;

go_left = true;

//カーソルの遅延アニメーション
//ほんの少ーーーしだけ遅延させる 0.001秒
TweenMax.to({}, .001, {
  repeat: -1,
  onRepeat: function() {

    //posX += (mouseX - posX) / delay;
    posY += (mouseY - posY) / delay;

    //console.log('mouse' + mouseX);
    //console.log('pos' + posX);
    if (go_left) {
      if (posX >= mouseX) {
        //console.log('go_left');
        //console.log('go_left');
        left_val = posX;
        posX += (mouseX - posX) / delay;
        $('.c-cursor__follower').css('transform', 'scaleX(1)');

      }
    } else {
      //left_val = posX - 100;

      if ((posX + 100) < mouseX) {
        //console.log('go_right');
        //left_val = posX - 100;
        left_val = posX;
        //posX += (mouseX - posX) / delay;
        posX += (mouseX - (posX + 100)) / delay;
        $('.c-cursor__follower').css('transform', 'scaleX(-1)');
      }
    }


    //left_val = posX - (fWidth / 2);
    //left_val = posX;
    top_val = posY - (fWidth / 2);
    //top_val = posY;



    if ((window.outerWidth - fWidth) < posX) {
      left_val = window.innerWidth - fWidth -20;
    }

    if ((document.documentElement.scrollHeight - fWidth) < posY) {
      //console.log('over');
      top_val = document.documentElement.scrollHeight - fWidth;
    }


    TweenMax.set(follower, {
        css: {
          left: left_val,
          top: top_val
        }
    });


    //TweenMax.set(cursor, {
    //    css: {
    //      left: mouseX - (cWidth / 2),
    //      top: mouseY - (cWidth / 2)
    //    }
    //});
    //
  }
});

//マウス座標を取得
$(document).on("mousemove", function(e) {


    if ($('.c-cursor__follower img').attr('src') == '/images/cursor.gif') {

    } else {
      $('.c-cursor__follower img').attr('src', '/images/cursor.gif');
    }

    if (posX < e.pageX) {
        //console.log('→');
        //$('.c-cursor__follower').css('transform', 'scaleX(-1)');
        go_left = false;
    } else {
        //console.log('←');
        //$('.c-cursor__follower').css('transform', 'scaleX(1)');
        go_left = true;
    }

    mouseX = e.pageX;
    mouseY = e.pageY;

    clearTimeout(moveTimer);
    moveTimer = setTimeout(function(){
      //console.log('stop');
      $('.c-cursor__follower img').attr('src', '/images/cursor_stop.png');
    }, delayTime);




});
$("a").on({
  "mouseenter": function() {
    //cursor.addClass("is-active");
    follower.addClass("is-active");
  },
  "mouseleave": function() {
    //cursor.removeClass("is-active");
    follower.removeClass("is-active");
  }
});
*/

/**
 * SNOW LIVE一覧
 */
if ($('.s-snow-lives').length > 0) {
  let images = document.querySelectorAll('.s-snow-lives__detal--pic img');
  let iframes = document.querySelectorAll('.s-snow-lives__detal--iframe img');

  let ts, i;
  images.forEach(function(element) {
    element.setAttribute('data', element.getAttribute('src'));
  });
  iframes.forEach(function(element) {
    element.setAttribute('data', element.getAttribute('src'));
  });

  // 定期更新処理
  setInterval(function() {
    ts = '?ts=' + new Date().getTime();

    images.forEach(function(element) {
      element.setAttribute('src', element.getAttribute('data') + ts);
    });
    iframes.forEach(function(element) {
      element.setAttribute('src', element.getAttribute('data') + ts);
    });

  }, 300000);

  let col = document.getElementById('columns');
  let item = document.querySelector('.ski-areas');
  /*
  col.addEventListener('change', function() {
    console.log(item.classList);
    item.classList.toggle('column2');
  });
  */

  let ski_area = document.querySelectorAll('.ski-area');
  let click_area = document.createElement("div");
  click_area.classList.add('click_area');
  let body = document.querySelector('body');

  ski_area.forEach(function(area) {
    let work = click_area.cloneNode(true);
    work.addEventListener('click', function(element) {
      let id = element.target.parentNode.getAttribute('id');
      location.href = 'http://192.168.0.144:8099/taisetsu-snow-live/' + id;
    /*
      let click_status = element.target.parentNode.classList.contains('selected');

      let click_areas = document.querySelectorAll('.click_area');
      click_areas.forEach(function(area) {
        area.parentNode.classList.remove('selected');
      });

      // 拡大
      if (click_status === false) {
        element.target.parentNode.classList.add('selected');
      }
      body.classList.toggle('hold');
      */
    });
    area.append(work);
  });

  // iframe scrolling no
  $(document).ready(function() {
    $('.s-snow-lives__detail--iframe a').fancybox({
      'type': 'iframe',
      'iframe': {
        'attr' : {
          'scrolling': 'no',
        },
      },
    });
  });

}


/**
 * SNOW LIVE一覧
 */
if ($('.s-snow-lives').length > 0) {

  /**
   * snow live画像のサイズ調整する
   */
  function snowlive_resize() {

    // 画面幅
    var windowWidth = parseInt(window.innerWidth);

    // breakpoint
    var replaceWidth2 = 426;
    var replaceWidth3 = 780;

    // リスト以外の高さ
    var otherHeight;

    // リストの行数
    var rows;

    // リストの列数
    var col;

    // 2行3列表示
    if (windowWidth >= replaceWidth3) {
      otherHeight = 240;
      rows = 2;
      col = 3;
    // 3行2列表示
    } else if(windowWidth >= replaceWidth2) {
      otherHeight = 200;
      rows = 3;
      col = 2;
    } else {
      col = 1;
    }

    // 1データの幅
    let box_width = ((windowWidth - 16) / col) - (8 * col);
    let pic_width = (box_width - 16 - 5) / 2;
    let pic_height = (103 / 147.156) * pic_width;

    $('.s-snow-lives__detail--pic figure').width(pic_width);
    $('.s-snow-lives__detail--pic figure').height(pic_height);
    $('.s-snow-lives__detail--iframe figure').width(pic_width);
    $('.s-snow-lives__detail--iframe figure').height(pic_height);
    $('.underconstruction').width(pic_width);
    $('.underconstruction').height(pic_height);

    // スマホは以降処理無し
    if (col == 1) {
      return;
    }

    // 高さがなく画面からはみ出る場合
    if ($('.s-snow-lives__list').height() + otherHeight >= window.innerHeight) {

      let over_height = $('.s-snow-lives__list').height() + otherHeight - window.innerHeight;

      let new_pic_height = pic_height - (over_height / rows);

      // 変更の高さの比率
      let raito_height = new_pic_height / pic_height;

      pic_width = pic_width * raito_height;
      pic_height = new_pic_height;

      $('.s-snow-lives__detail--pic figure').width(pic_width);
      $('.s-snow-lives__detail--pic figure').height(pic_height);
      $('.s-snow-lives__detail--iframe figure').width(pic_width);
      $('.s-snow-lives__detail--iframe figure').height(pic_height);
      $('.underconstruction').width(pic_width);
      $('.underconstruction').height(pic_height);
    }


  }

  snowlive_resize();

  $(window).resize(function() {
    snowlive_resize();
  });
}

})( jQuery );